
#home { 
  padding-top: 100px;
  background-color: #fff;
}
#home h3 {
  font-size: 22px;
  margin: 0 0 20px;
}
#home h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  font-size: 2.8rem;
}
#home h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}
#home .home-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
  font-size: 20px;
}
#home .home-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 30px;
  font-weight: 300;
  padding-right: 8px;
}
#home .home-text p {
  margin: 30px 0;
  font-size: 25px;
  text-align: justify;
  line-height: 1.2;
}

#home .home-text h2 {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 15px;
    font-size: 47px;
    text-align: left;
}

#home img {  
  width:100%;     
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}
#home p {
  line-height: 24px;
  margin: 30px 0;
}

.stats-container {
  display: flex;
  justify-content: space-around;  
  align-items: center;  
  margin-top: 20px; 
}

.stat {
  text-align: center;  
  flex: 1; 
}

.large-number {
  font-size: 4rem !important;
  font-weight: 800;
  font-family:Arial, Helvetica, sans-serif
}


.stat p {
  font-size: 2rem; 
}
#home2 {
  padding: 100px 0;
  background: linear-gradient(to right, #0d1a8b 0%, #3996f8 100%);
  color: #fff;
}
#home2 .service-desc {
  margin: 10px 10px 20px;
}
#home2 h2 {
  color: #fff;
}
#home2 .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
#home2 i.fa {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  border-radius: 50%;
  color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}
#home2 h3 {
  font-weight: 500;
  padding: 5px 0;
  color: #fff;
}
#home2 p {
  color: rgba(255, 255, 255, 0.75);
}
#home2 .service-desc {
  margin-bottom: 40px;
}
.swiper-container {
  height: 500px; /* Establece la altura deseada */
}
#home4 {  
  background-color: #fff; 
  padding-bottom: 20px;
}
 
#home4 h3 {
  font-size: 22px;
  margin: 0 0 20px;
}
#home4 h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
} 

#home4 .home-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
  font-size: 15px;
}
#home4 .home-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 30px;
  font-weight: 300;
  padding-right: 8px;
} 

#home4 img {
  width: 450px;
  height: auto;
  margin-top: 10px;
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
  align-items: center;  
} 
#home4 .home-text p {
  margin: 30px 0;
  font-size: 25px;
  text-align: justify;
  line-height: 1.2;
}


@media (max-width: 768px) {
  #home { 
    padding-top: 20px; 
  }
  #home .home-text h2 {
      position: relative;
      margin-bottom: 15px;
      padding-bottom: 15px;
      font-size: 27px;
      text-align: left;
  } 
  #home .home-text p {
     
    font-size: 15px;
    text-align: justify;
    line-height: 1.2;
  }
  #home .home-text li {
    margin-bottom: 6px;
    margin-left: 6px;
    list-style: none;
    padding: 0;
    font-size: 12px;
  }
  #home .home-text li:before {
    content: "\f00c";
    font-family: "FontAwesome";
    color: #5ca9fb;
    font-size: 10px;
    font-weight: 300;
    padding-right: 8px;
  }
  #home img {  
    width: 90%;   
    height: auto; 
    margin-top: 5px; 
  }
  #home4 img {
    width: 90%;
    height: auto;  
  }
  #home4 .home-text p {
     
    font-size: 15px;
    text-align: justify;
    line-height: 1.2;
  }
  #home4 .home-text li {
    margin-bottom: 6px;
    margin-left: 6px;
    list-style: none;
    padding: 0;
    font-size: 12px;
  }
  #home4 .home-text li:before {
    content: "\f00c";
    font-family: "FontAwesome";
    color: #5ca9fb;
    font-size: 10px;
    font-weight: 300;
    padding-right: 8px;
  }
}
@media (max-width: 768px) {
  .large-number {
    font-size: 2rem !important;
    font-weight: 800;
    font-family:Arial, Helvetica, sans-serif
  }  
}
