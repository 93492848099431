 
#carousel { 
  padding-top: 20px;
  background-color: #fff; 
  padding-bottom: 20px;
}
.carousel-container {
  width: 80%;
  margin: auto;
}

.carousel-container img {
  width: 100%;
  height: auto;
}
 .imagen-carousel{

    width: 270px ;
    height:270px 
  }
@media (max-width: 768px) {
  #carousel { 
    padding-top: 5px;
    background-color: #fff; 
    padding-bottom: 5px;
  } 
 .imagen-carousel{
    width: 100px ;
    height: 100px ; 
  }
}