#menu {
  padding: 15px;
  transition: all 0.8s;
}
#menu.navbar-default {
  background-color: #000000;
  border-color: rgba(255, 255, 255, 0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
} 
#menu.navbar-default .navbar-nav > li > a {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #d1d1d1;
  font-size: 20px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
  font-weight: bold; 
}
#menu.navbar-default .navbar-nav > li > a:hover {
  color: #ffffff; 
}

#menu.navbar-default .navbar-nav > li > a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}  
.navbar {
  background-color: #000;
  border: none;
} 
/* Cambia el color del texto al pasar el mouse */
.navbar-nav > li > a:hover,
.navbar-nav > li > a:focus {
  color: #000000; /* Puedes ajustar este color según tu diseño */
}  
/* Estilo del logo para que se adapte bien al diseño */
.navbar-logo {
  max-height: 50px;
  width: auto;
}
/* Mejoras para el botón de navegación en dispositivos móviles */
.navbar-toggle {
  border: none;
  background: transparent;
} 
/* Botón de alternancia en móviles con barras blancas */
.navbar-toggle .icon-bar {
  background-color: #000000;
} 

/* Ajustes para que el menú se vea bien en dispositivos móviles */
@media (max-width: 768px) {
  .navbar {
    width: 100%; /* Asegura que el navbar ocupe todo el ancho de la pantalla */
  }
  .navbar-nav {
    width: 100%;
    background-color: #000;
  }
  .navbar-nav > li {
    float: none;
    display: block;
    width: 100%;
  } 
  .navbar-nav > li > a {
    padding: 10px 20px;
  } 
  .navbar-collapse {
    border-top: 1px solid #e7e7e7;
    margin-top: 10px;
  } 
  .navbar-collapse.collapse {
    display: none !important;
    background-color: white;
  } 
  .navbar-collapse.collapse.in {
    display: block !important;
    background-color: white;
  } 
}  

.section-title {
  margin-bottom: 70px;
}
.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
.section-title p {
  font-size: 18px;
}
.btn-custom {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff;
  background-image: none;
  background-color: #6372ff;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
} 
  
/* Logo a la izquierda */
.navbar-header {
  float: left;
  width: auto;
}
 
/* Centrar el menú */
.navbar-nav {
  display: inline-block;
  float: none; 
  margin: 0 auto;  
  position: absolute;
}

.navbar-center {
  display: flex;
  justify-content: center;  /* Centrar horizontalmente los elementos */
  align-items: center;      /* Alinear verticalmente */
  width: 100%;              /* Hacer que ocupe todo el ancho disponible */
  padding: 0;               /* Eliminar el padding por defecto */
}

@media (max-width: 768px) {
  .navbar-header {
    float:none !important; 
    width: auto;
  } 
  .section-title {
    margin-bottom: 20px;
  }
}
 
@media (max-width: 768px) {
  .navbar-center {
    flex-direction: column; 
    justify-content: flex-start; 
  }

  .navbar-center li {
    margin: 10px 0; 
  }

  .navbar-toggle {
    display: block;  
  }
}
 