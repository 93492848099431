@media screen and (max-width: 400px ) {
  #features {
    padding: 20px;
    width: 100%;
  }
  #about{
     width: 100%;
  }
  #services,
  #testimonials,
  #team{
     width: 100%;
  }
  #contact,
  #footer {
     width: 100%;
  }

  #lema {
     width: 100%;
  }
  
}

.initial-logo-container {
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 100vh; 
  background-color: #000000; 
  overflow: hidden;
}

.initial-logo {
  height: 100%; /* Mantiene la altura de la imagen al 100% del contenedor */  
  object-fit: contain; 
  transform: scaleX(1.2); 
  animation: fadeIn 3s ease-in-out, fadeOut 1s 10002.6s ease-in-out; /* Agregada la animación fadeOut después de 3 segundos */
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.overlay-logo-container {
  position: absolute;  
  top: 50%;  
  left: 50%;  
  transform: translate(-50%, -50%);  
}

.overlay-logo {
  max-width: 80%; 
  height: auto;  
   animation: pulse 2s infinite, fadeOut 1s 2000s ease-in-out;  
}
 
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}


@media (max-width: 768px) {  
  .overlay-logo {
    max-width: 130%; 
    height: auto;  
    animation: pulse 2s infinite, fadeOut 1s 2000s ease-in-out;  
    padding-right: 50px;
  }
} 