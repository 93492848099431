/* styles.css */

.infographic-container { 
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px; 
}

 
.feature-image {
  max-width: 450px;
  width: 100%;
  height: auto;
  display: block;
  margin: 20px;
}

.feature-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.815);
  color: #000000 !important;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  padding: 16px;
  cursor: pointer; 
}
 

.feature-title {
  font-size: 2.65rem;
  margin-bottom: 8px;
  color: #000000 !important;
  text-transform: uppercase; 
  font-weight: bold; 
  height: 50px; 
}

.feature-description {
  font-size: 2.8rem;
  text-align: center; 
  color: rgb(0, 0, 0) !important;
  text-align: justify;
}

.feature-card .list-style {
  text-align: left;
  font-size: 3rem;
}
.feature-card .list-style li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
}
.feature-card .list-style li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 45px;
  font-weight: 400;
  padding-right: 8px;
}

/* Estilos para pantallas pequeñas */
@media (max-width: 768px) { 
  .feature-title {
    font-size: 1.8rem; /* Tamaño de fuente más pequeño */
  }

  .feature-description {
    font-size: 2rem; /* Tamaño de fuente más pequeño */
    padding-left: 40px;
    padding-right: 40px;
  }

  .feature-card .list-style {
    text-align: left;
    font-size: 2rem;
  }
  .feature-card .list-style li {
    margin-bottom: 6px;
    margin-left: 6px;
    list-style: none;
    padding: 0;
  }
  .feature-card .list-style li:before {
    content: "\f00c";
    font-family: "FontAwesome";
    color: #5ca9fb;
    font-size: 25px;
    font-weight: 400;
    padding-right: 8px;
  }

  .feature-image {
    max-width: 300px; 
    height: auto;
    display: block;
    margin: 10px;
    display: none;
  }

}