#home3 { 
    background-color: #ffffff;  
    display: grid; 
    overflow-x: hidden; 
    overflow-y: hidden;
    height: 850px;
    background: #ffffff; 
    opacity: 0;
    animation: fadeInSlideUp 2s ease forwards; 
    animation-delay: calc(2s * var(--animation-order));  
}

@keyframes fadeInSlideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
 
#home3 .service-desc {
  margin: 10px 10px 20px;
}
#home3 h2 {
  color: #141010;
  padding-top:  15px;
}
#home3 .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0; 
  left: 50%;
}

#home3 .assembly {
  position: relative;
  display: flex;
  justify-content: center; /* Centra los elementos horizontalmente */
  align-items: center; /* Centra los elementos verticalmente */
  flex-wrap: wrap; /* Permite que los elementos se acomoden si no hay suficiente espacio */
  width: 100%; /* Asegúrate de que el contenedor ocupe todo el ancho disponible */
  max-width: 100%; /* Evita que el contenedor desborde su contenedor padre */
  margin: 0 auto; /* Centra el contenedor horizontalmente */
 padding-top:  10vh;
  padding-left: 15vh;
}
  
* {
  margin: 0;
  padding: 0;
}
 
.assembly, article {
  transform-style: preserve-3d;
}

article {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  padding: 0.125em;
  width: 14em;
  height: 14em;
  border-radius: 50%;
  transform: translate(-50%, -50%) rotate(calc(var(--k)*1turn)) translatey(calc(-0.125em - 100%)) rotate(calc(var(--k)*-1turn));
  background: linear-gradient(30deg, #ffffff, #ffffff) content-box, linear-gradient(30deg, rgb(192, 192, 192), rgb(192, 192, 192)) padding-box;
  font: 1em/1.25 trebuchet ms, verdana, arial, sans serif;
  text-align: center;
  text-shadow: 0 0 1px #000, 1px 1px #ffffff; 
}
article:before {
  box-sizing: inherit;
  position: absolute;
  top: -2.25em;
  right: -2.25em;
  bottom: -2.25em;
  left: -2.25em;
  border: solid 0.125em #fff;
  padding: 1.25em;
  border-radius: 50%;
  transform: translatez(-20px) rotate(calc(var(--k)*1turn)) rotatex(calc(var(--s, -1)*1deg));
  background: radial-gradient(#ffffff calc(6em - 1px), rgba(255, 255, 255, 0) 6em) currentcolor;
  --m: linear-gradient(red, red) content-box, linear-gradient(red, red) border-box;
  -webkit-mask: var(--m);
  -webkit-mask-composite: xor;
  mask: var(--m);
  mask-composite: exclude;
  content: ""; 
}
article:nth-child(2n):before {
  --s: 1 ;
}
 
#home3 h3 { 
    line-height: 2;
    display: block;
    font-size: 1.4em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    unicode-bidi: isolate; 
	text-transform: uppercase; 
}

@media (max-width: 480px) {
  #home3 {   
    height: 550px;    
  }  
  /* Título */
  #home3 .assembly {
  position: relative;
  display: flex;
  justify-content: center;  
  align-items: center;  
  flex-wrap: wrap; 
  width: 100%;  
  max-width: 100%; 
  margin: 0 auto; 
   
  padding-left: 100px;
} 
  #home3 h3 {  
    font-size: 0.8em;  
	text-transform: uppercase; 
 }
 
    /* Artículos */
  article { 
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    padding: 0.08em;
    width: 8em;
    height: 8em;
    border-radius: 50%;
    transform: translate(-50%, -50%) rotate(calc(var(--k)*1turn)) translatey(calc(-0.1em - 100%)) rotate(calc(var(--k)*-1turn));
    background: linear-gradient(10deg, #fdfdfd, #ffffff) content-box, linear-gradient(10deg, rgb(180, 180, 180), rgb(187, 187, 187)) padding-box;
    font: 1em/1.25 trebuchet ms, verdana, arial, sans serif;
    text-align: center;
    text-shadow: 0 0 1px #000, 1px 1px #ffffff;
    }
    article:before {
    box-sizing: inherit;
    position: absolute;
    top: -2.5em;
    right: -2.5em;
    bottom: -2.5em;
    left: -2.5em;
    border: solid 0.1em #fff;
    padding: 1.8em;
    border-radius: 50%;
    transform: translatez(-20px) rotate(calc(var(--k)*1turn)) rotatex(calc(var(--s, -1)*1deg));
    background: radial-gradient(#fff calc(4em - 1px), rgba(255, 255, 255, 0) 4em) currentcolor;
    --m: linear-gradient(red, red) content-box, linear-gradient(red, red) border-box;
    -webkit-mask: var(--m);
    -webkit-mask-composite: xor;
    mask: var(--m);
    mask-composite: exclude;
    content: "";
    }
    article:nth-child(2n):before {
    --s: 1 ;
    }
 
}