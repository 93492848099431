
#about {
  padding-top: 100px;
  background-color: black; /* Fondo negro inicial */ 
  animation: fadeIn 2s forwards; /* Duración de la animación */
}
#about .intro-text {
  padding-top: 110px;
  padding-bottom: 180px;
  text-align: center;
}
 
#about .intro-text h1 {
  font-size: 8.6rem;   
  text-align: justify;
}
 
#about .intro-text p {
  font-size: 4.5rem; 
  text-align: justify;
  line-height: 1.2;
}
 
#about2 { 
  padding-top: 100px;
  background-color: #fff;
}
#about2 h3 {
  font-size: 22px;
  margin: 0 0 20px;
}
#about2 h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  font-size: 47px;
  text-align: justify; 
}
#about2 h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}
 
#about2 .home-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
  font-size: 17px; 
}
#about2 .home-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 30px;
  font-weight: 300;
  padding-right: 8px;
}
#about2 img {
  width: 100%;
  margin-top: 10px;
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}
#about2 p {
  line-height: 24px;
  margin: 30px 0;
  font-size: 25px; 
  text-align: justify; 
  line-height: 1.5;
} 
#pillars { 
  padding-top: 100px;
  background-color: #fff;
}
#pillars h3 {
  font-size: 22px;
  margin: 0 0 20px; 
}

#pillars p {
  font-size: 24px;
 }

#pillars h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

@media (max-width: 768px) {
  #about2 {
    padding-top: 20px; 
    animation: fadeIn 2s forwards; /* Duración de la animación */
  } 
  #pillars { 
    padding-top: 20px; 
    animation: fadeIn 2s forwards; 
  } 
  #pillars p {
  font-size: 12px;
 }
 #about2 img {
  height: 250px;
}
} 
/* Team Section */
#team {
  padding: 100px 0;
  background-color: #fff; 
}
#team h4 {
  margin: 5px 0;
}
#team .team-img {
  width: 100%;
  max-width: 240px; /* Se asegura que la imagen sea responsiva */
  margin: 0 auto;   /* Centra la imagen dentro de su contenedor */
}
#team .thumbnail {
  background: transparent;
  border: 0;
  text-align: center; /* Centra el contenido de la miniatura */
  margin-bottom: 30px; /* Añade espacio entre las filas */
  height: 500px;
}
#team .thumbnail .caption {
  padding: 10px 0 0;
  color: #888;
}

#team .image-placeholder {
  background-color: #f0f0f0; /* Fondo claro para el placeholder */
  position: relative;
  overflow: hidden;
}

.team-img { 
  height: 350px;
  display: block;
  opacity: 0; /* Empieza invisible */
  transition: opacity 0.5s ease-in-out; /* Suaviza la transición */
}

@media (max-width: 768px) {
  #team {
    padding: 20px 0;
    background-color: #fff; 
    width: 100%;
  }
  #team .section-title h2{
    font-size: 2.5rem;
  }

  #about .intro-text {
    padding-top: 20px;
    padding-bottom: 0px;
    text-align: center;
  }
   #about .intro-text h1 {
    font-size: 2rem;
    margin-bottom: 15px; 
    margin-left: 40px; 
    margin-right: 40px; 
  }

   #about .intro-text p {
    font-size: 1.5rem;
    margin-bottom: 30px;
    margin-left: 40px; 
    margin-right: 40px; 
    line-height: 1.2;
  }

   #about.intro-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  #about2 h2 {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 15px;
    font-size: 27px;
    text-align: justify; 
  }
  #about2 .stat p{ 
    font-size: 1rem;
  }
  #about2 p {
    
    margin: 10px 0;
    font-size: 15px;  
  } 

  #about2 .home-text li {
    margin-bottom: 1px;
    margin-left: 1px;
    list-style: none;
    padding: 0;
    font-size: 10px; 
  }
  #about2 .home-text li:before { 
    font-size: 15px; 
  }
}

#about2 .stats-container {
  display: flex;
  justify-content: space-around;  
  align-items: center;  
  margin-top: 20px; 
}

#about2 .stat p{
  text-align: center;  
  flex: 1; 
}
