#services {
  padding-top: 100px;
  background-color: black; /* Fondo negro inicial */ 
  animation: fadeIn 2s forwards; /* Duración de la animación */
}
#services .intro-text {
  padding-top: 110px;
  padding-bottom: 180px;
  text-align: center;
}
  
#services .intro-text h1 {
  font-size: 8.6rem;   
  text-align: justify;
}
 
#services .intro-text p {
  font-size: 4.5rem; 
  text-align: justify;
  line-height: 1.2;
}
  
#services2 {
  padding: 100px 0;
  background:  #ffffff;
  color: #ff0404;
  opacity: 0;
  animation: fadeInSlideUp 2s ease forwards; 
  animation-delay: calc(2s * var(--animation-order));  
  width: 100%;
}
#services2 .service-desc {
  margin: 10px 10px 20px;
}
#services2 h2 {
  color: #000000;
}
#services2 .section-title h2::after {
  position: absolute; 
  background: rgba(138, 138, 138, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0; 
  left: 50%;
}
#services2 i.fa {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  border-radius: 50%;
  color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}
#services2 h3 {
  font-weight: 500;
  padding: 5px 0;
  color: #fff; 
}
#services2 p {
  color: rgba(255, 255, 255, 0.75);
}
#services2 .service-desc {
  margin-bottom: 40px;
}
/**/
 
#services2 .services-section {
  padding: 60px 20px;
  background-color: #f0f0f0;
  text-align: center;
}
 
#services2  .service-item {
  background-color: #ffffff;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;  
  height: 370px;
  margin-bottom: 20px;
}

#services2 .service-item h3 {
  margin-bottom: 15px;
  font-size: 2.5rem;
  color: #ffffff; 
  text-transform: uppercase; 
  font-weight: bold; 
}

#services2 .service-item p {
  margin: 0;
  font-size: 2rem;
  color: #ffffff; /* Color de texto para los párrafos */
  line-height: 1.2;
  text-align: justify;
}

#services2 .service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

/* Media queries para dispositivos móviles */
@keyframes fadeInSlideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
 
@media (max-width: 768px) {
  #services2 .services-section {
    padding: 60px 20px;
    background-color: #f0f0f0;
    text-align: center;
  }
  
  #services2  .service-item {
    background-color: #ffffff;
    padding: 35px; 
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;  
    height: 350px;
    margin-bottom: 20px;
    opacity: 0;
    animation: fadeInSlideUp 2s ease forwards; 
    animation-delay: calc(2s * var(--animation-order)); 
  } 
  #services2  .service-item h3 {
    margin-bottom: 15px;
    font-size: 2.2rem;
    color: #000000; 
    text-transform: uppercase;  
    font-weight: 1000;
  }

  #services2  .service-item p {
    margin: 0;
    font-size: 1.8rem;
    color: #ffffff; /* Color de texto para los párrafos */
    line-height: 1.2;
    text-align: justify;
   font-weight: bold;
  } 
}
 

@media (max-width: 768px) {
  #services .intro-text {
    padding-top: 100px;
    padding-bottom: 0px;
    text-align: center;
  }
   #services .intro-text h1 {
    font-size: 2rem;
    margin-bottom: 15px; 
    margin-left: 40px; 
    margin-right: 40px; 
  }

   #services .intro-text p {
    font-size: 1.5rem;
    margin-bottom: 30px;
    margin-left: 40px; 
    margin-right: 40px; 
    line-height: 1.2;
  }

   #services.intro-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
}

#services3 {
  padding: 100px 0;
  background:  #ffffff;
  opacity: 0;
  animation: fadeInSlideUp 2s ease forwards; 
  animation-delay: calc(2s * var(--animation-order));  
}
#services3 .service-desc {
  margin: 10px 10px 20px;
}
#services3 h2 {
  color: #000000;
}
#services3 .section-title h2::after {
  position: absolute; 
  background: rgba(138, 138, 138, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0; 
  left: 50%;
}
#services3 i.fa {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  border-radius: 50%;
  color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}
#services3 h3 { 
  padding: 5px 0;
  color: #000000; 
}
#services3 p {
  color: rgba(0, 0, 0, 0.75);
}
#services3 .service-desc {
  margin-bottom: 40px;
}
/**/
 
#services3 .services-section {
  padding: 60px 20px;
  background-color: #f0f0f0;
  text-align: center;
}
 
#services3 .service-item {
  background-color: #ffffff;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;  
  height: 370px;
  margin-bottom: 20px;
}

#services3 .service-item h3 {
  margin-bottom: 15px;
  font-size: 2.5rem;
  color: #ffffff; 
  text-transform: uppercase; 
  font-weight: bold; 
}

#services3 .service-item p {
  margin: 0;
  font-size: 2rem;
  color: #ffffff; /* Color de texto para los párrafos */
  line-height: 1.2;
  text-align: justify;
}

#services3  .service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}
