.footer {
  background-color: #1a1a1a; /* Fondo oscuro para el pie de página */
  color: #e0e0e0; /* Texto claro para contraste */
  padding: 40px 20px; /* Espaciado interno */
  font-family: Arial, sans-serif; /* Fuente clara */
}

.footer .container {
  width: 90%;
  max-width: 1200px; /* Ancho máximo del contenedor */
  margin: 0 auto;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  padding-top: 20px;
  padding-bottom: 20px;
  min-width: 250px; /* Ancho mínimo de las secciones */ 
  position: relative;
}

.footer-section h3 {
  color: #dbdada; /* Color claro para los títulos */
  border-bottom: 2px solid #444; /* Línea debajo del título */
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.footer-section h3:hover {
  color: #ffffff; /* Color claro para los títulos */
  border-bottom: 2px solid #444; /* Línea debajo del título */
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.footer-section p {
  margin: 10px 0;
}

.footer-section.links ul {
  list-style-type: none;
  padding: 0;
}

.footer-section.links ul li {
  margin-bottom: 10px;
}

.footer-section.links ul li a {
  color: #b8b8b8; /* Color claro para los enlaces */
  text-decoration: none;
}

.footer-section.links ul li a:hover {
  color: #ffffff; /* Color más claro en hover */
  text-decoration: underline;
}

.footer-section.contact .socials {
  margin-top: 10px;
}

.footer-section.contact .socials a {
  color: #b1b1b1; /* Color claro para iconos sociales */
  margin-right: 15px;
  font-size: 20px;
  text-decoration: none;
}

.footer-section.contact .socials a:hover {
  color: #f1f1f1; /* Color más claro en hover */
}

.footer-bottom {
  text-align: center; /* Centra el texto */
  padding: 20px 0; /* Espaciado interno */
  border-top: 1px solid #444; /* Línea superior */
  margin-top: 20px;
}

.footer-bottom p {
  margin: 5px 0;
  font-size: 14px; /* Tamaño de fuente más pequeño */
}
